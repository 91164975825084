window.addEventListener("load", function () {
  console.log("Popup script running...");

  document.body.addEventListener("click", function (event) {
    const popupTrigger = event.target.closest(".popup-btn");
    const popupClose = event.target.closest(".popup-close");
    const overlayClick = event.target.classList.contains("popup");

    // **Open Popup**
    if (popupTrigger) {
      console.log("Opening popup:", popupTrigger.dataset.target);
      const popup = document.querySelector(popupTrigger.dataset.target);
      if (popup) {
        popup.classList.remove("hidden");
        document.body.classList.add("overflow-hidden");
      }
    }

    // **Close Popup (if clicking close button OR outside popup)**
    if (popupClose || overlayClick) {
      console.log("Closing popup...");
      const popup =
        event.target.closest(".popup") ||
        document.querySelector(".popup:not(.hidden)");
      if (popup) {
        popup.classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      }
    }
  });
});
